import React from "react"
import Header from "../Header"
import Footer from "../Footer"
import theme from "../../theme"
import GlobalStyle from "../../theme/reset"
import { ThemeProvider } from "styled-components"

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
