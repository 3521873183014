import React from "react"
import Menu from "./Menu"
import Logo from "../Logo"
import styled from "styled-components"
import BaseColumn from "../MainColumn"
import { palette, theme } from "styled-tools"

const Wrapper = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 6rem;
  position: sticky;
  background-color: ${palette("white")};
  box-shadow: ${theme("boxShadow.main")};
`

const MainColumn = styled(BaseColumn)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function Header() {
  return (
    <Wrapper>
      <MainColumn>
        <Logo />
        <Menu />
      </MainColumn>
    </Wrapper>
  )
} 

export default Header
