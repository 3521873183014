import styled from "styled-components"
import { prop } from "styled-tools"
import { screen } from "../../theme/breakpoints"

const MainColumn = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: ${prop("maxWidth", "121.6rem")};

  @media ${screen.tablet} {
    width: calc(100% - 3.2rem * 2);
  }
`

export default MainColumn
