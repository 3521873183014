import React from "react"
import styled from "styled-components"
import FacebookIcon from "../../images/facebook-icon.svg"
import WhatsappIcon from "../../images/whatsapp-icon.svg"
import InstagramIcon from "../../images/instagram-icon.svg"
import { palette } from "styled-tools"

const List = styled.ul`
  display: flex;
  margin: 1.6rem 0;
  align-items: center;
`

const ListItem = styled.li`
  margin: 0 1.6rem;
  color: ${palette("grey", 1)};

  &:nth-child(1):hover { color: #2c4b8a; }
  &:nth-child(2):hover { color: #3aa74b; }
  &:nth-child(3):hover { color: #a71c41; }
`

const ListItemLink = styled.a`
  color: inherit;
  display: block;
`

function Social() {
  const socialItems = [
    {
      icon: FacebookIcon,
      label: "Visite nosso facebook",
      url: "https://www.facebook.com/salim.alimentos.39"
    },
    {
      icon: WhatsappIcon,
      label: "Visite nosso instagram",
      url: "https://www.instagram.com/alimentossalim/"
    },
    {
      icon: InstagramIcon,
      label: "Nos mande uma mensagem",
      url: "https://wa.me/553198923054"
    }
  ]
  
  return (
    <List>
      {socialItems.map(({ icon: Icon, ...item }) => (
        <ListItem title={item.label} key={item.label}>
          <ListItemLink href={item.url} target="_blank" rel="noopener noreferrer">
            <Icon />
          </ListItemLink>
        </ListItem>
      ))}
    </List>
  )
}

export default Social
