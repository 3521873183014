export default {
  palette: {
    primary: ["#4B2F1F", "#9B725A", "#F1EBE8"],
    secondary: [],
    grey: ["#495057", "#494949", "#FAFAFA", "#e4e4e4", "#cecece"],
    danger: ["#E31E49"],
    success: ["#66BD7D", "#e0feef", "#066631"],
    warning: ["#EB9E09", "#FFFBE6"],
    info: ["#f5f8ff", "#002ca6"],
    black: "#0E1320",
    white: "#FFFFFF"
  },
  fontFamily: {
    primary: "Work Sans",
    secondary: "PT Serif"
  },
  fontWeight: {
    bold: 700,
    semibold: 600,
    medium: 500,
    regular: 400,
    light: 300
  },
  boxShadow: {
    main: "0 2px 2px rgba(14, 19, 32, 0.08)",
    secondary: "2px 1px 4px rgba(0, 0, 0, 0.1)",
    spread: "0 7px 11px -4px rgba(0,23,62,0.2), 0 0 1px 0 #a8b9d5"
  },
  times: ["89ms", "144ms", "233ms", "377ms", "610ms", "987ms"]
}
