import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette, theme } from "styled-tools"

const List = styled.ul`
  display: flex;
  align-items: center;
`

const ListItemLink = styled(Link)`
  font-size: 1.4rem;
  margin-right: 2.4rem;

  &.active {
    font-weight: ${theme("fontWeight.bold")};
  }
`

const LastItemLink = styled(Link)`
  display: block;
  margin-left: 1rem;
  font-size: 1.2rem;
  border-radius: 3rem;
  padding: 0.5rem 1.2rem;
  text-transform: uppercase;
  color: ${palette("white")};
  background-color: ${palette("primary")};
  font-weight: ${theme("fontWeight.semibold")};

  &:hover {
    background-color: ${palette("primary", 1)};
  }
`

const menuItems = [
  { label: "Início", alt: "Salim: Alimentos inteligentes", route: "/" },
  { label: "Sobre", alt: "Sobre a Salim alimentos", route: "/sobre" },
  { label: "Produtos", alt: "Nossos Produtos", route: "/nossos-produtos" },
  { label: "Contato", alt: "Entre em contato", route: "/contato" }
]

function Menu() {
  return (
    <nav>
      <List>
        {menuItems.map(item => (
          <li key={item.route}>
            <ListItemLink alt={item.alt} title={item.title} activeClassName="active" to={item.route}>
              {item.label}
            </ListItemLink>
          </li>
        ))}        
        <li>
          <LastItemLink to="/comprar">Faça seu pedido</LastItemLink>
        </li>
      </List>
    </nav>
  )
}

export default Menu
