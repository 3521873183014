import React from "react"
import Logo from "../Logo"
import Social from "./Social"
import styled from "styled-components"
import BaseColumn from "../MainColumn"
import { palette, theme } from "styled-tools"
import { Link } from "gatsby"

const Wrapper = styled.footer`
  padding: 4.8rem 0;
  background-color: ${palette("white")};
`

const MainColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const TextContent = styled.div`
  margin: 2.4rem 0;
  text-align: center;
`

const Text = styled.div`
  font-size: 1.4rem;
  margin-top: 0.8rem;
  color: ${palette("grey")};
  font-weight: ${theme("fontWeight.bold")};
  font-family: ${theme("fontFamily.secondary")};
`

const Anchors = styled.ul`
  display: flex;
  align-items: center;
`

const AnchorItem = styled.li`
  display: flex;
  position: relative;
  margin-top: 0.8rem;
  font-family: ${theme("fontFamily.secondary")};

  &:not(:last-child) {
    margin-right: 2.4rem;

    &:after {
      content: "•";
      display: block;
      position: absolute;
      left: calc(100% + 8px);
      color: ${palette("primary", 1)};
    }
  }
`

const AnchorLink = styled(Link)`
  color: ${palette("primary", 1)};

  &:hover {
    color: ${palette("primary")};
  }
`

function Footer() {
  const menuItems = [
    { label: 'Início', route: '/' },
    { label: 'Sobre', route: '/sobre' },
    { label: 'Produtos', route: '/nossos-produtos' },
    { label: 'Contato', route: '/contato' }
  ]

  return (
    <Wrapper>
      <MainColumn>
        <Logo centralized />
        <TextContent>
          <Text>Salim Alimentos - CNPJ: 38.386.290/0001-26</Text>
          <Text>Todos direitos reservados - 2020</Text>
        </TextContent>
        <Social />
        <Anchors>
          {menuItems.map(item => (
            <AnchorItem key={item.route}>
              <AnchorLink to={item.route}>{item.label}</AnchorLink>
            </AnchorItem>
          ))}
        </Anchors>
      </MainColumn>
    </Wrapper>
  )
}

export default Footer
