import React from "react"
import styled from "styled-components"
import { palette, ifProp, theme } from "styled-tools"
import { Link } from "gatsby"

const Wrapper = styled(Link)`
  text-align: ${ifProp({ centralized: "true" }, "center", "left")};
`

const Title = styled.h1`
  font-size: 2.8rem;
  text-transform: uppercase;
  color: ${palette("primary")};
  font-weight: ${theme("fontWeight.bold")};
`

const Description = styled.h2`
  font-size: 1.4rem;
  margin-top: -0.7rem;
  letter-spacing: -0.054em;
  text-transform: lowercase;
  color: ${palette("primary", 1)};
  font-weight: ${theme("fontWeight.regular")};
`

const DescriptionBold = styled.span`
  color: ${palette("primary")};
  font-weight: ${theme("fontWeight.bold")};
`

function Logo({ centralized }) {
  return (
    <Wrapper centralized={`${centralized}`} to="/">
      <Title>Salim</Title>
      <Description>
        Alimentos <DescriptionBold>inteligentes</DescriptionBold>
      </Description>
    </Wrapper>
  )
}

export default Logo
